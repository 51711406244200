import React, {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import GenericMatchListModel from "@/data-models/generic-match-list.mjs";
import {
  EVENT_TFT_OVERLAY_USAGE,
  EVENT_TFT_ROUND_DATA,
  EVENT_TFT_ROUND_STATE,
  QUEUE_TYPES,
} from "@/game-lol/constants/constants.mjs";
import {
  getCurrentPatchForChampions,
  getCurrentPatchForStaticData,
  getDerivedId,
  getDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import {
  setOverlayFixtures,
  setOverlaySettings,
  uploadRoundData,
} from "@/game-tft/actions.mjs";
import * as API from "@/game-tft/api.mjs";
import {
  getCurrentSet,
  isFutureSet,
  isRevivalSet,
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_NAMES,
  revivalSets,
  setPatches,
  TOGGLES_CHAMPION,
  TOGGLES_LOADED_DICE,
} from "@/game-tft/constants.mjs";
import { INT_SCALE_FACTOR } from "@/game-tft/constants-in-game.mjs";
import fixturesInGameBenchmarking from "@/game-tft/fixtures-in-game-benchmarking.json";
import fixturesInGameLeveling from "@/game-tft/fixtures-in-game-leveling.json";
import fixturesInGameOpponentNext from "@/game-tft/fixtures-in-game-opponent-next.json";
// import fixturesInGameOpponentTags from "@/game-tft/fixtures-in-game-opponent-tags.json";
import fixturesInGameSettings from "@/game-tft/fixtures-in-game-settings.json";
import fixturesInGameStreamInfo from "@/game-tft/fixtures-in-game-stream-info.json";
import getStatFilters from "@/game-tft/get-stats-filters.mjs";
import { modifyChampionStruct } from "@/game-tft/in-game-utils.mjs";
import InGameOverlayAugments from "@/game-tft/InGameAugmentsOverlay.jsx";
import InGameComps from "@/game-tft/InGameComps.jsx";
import InGameOverlayItems from "@/game-tft/InGameItemsOverlay.jsx";
import InGameOverlayLeveling from "@/game-tft/InGameLevelingOverlay.jsx";
import InGameNextOpponentOverlay from "@/game-tft/InGameNextOpponentOverlay.jsx";
// import InGameOverlayOpponentTags from "@/game-tft/InGameOpponentTagsOverlay.jsx";
import InGameOverlayBenchmarking from "@/game-tft/InGameOverlayBenchmarking.jsx";
import InGameOverlayStreamInfo from "@/game-tft/InGameOverlayStreamInfo.jsx";
import InGameOverlaySettings from "@/game-tft/InGameSettingsOverlay.jsx";
import matchlistMergeFn from "@/game-tft/merge-function-matchlist.mjs";
import { SelectFilterDefaults } from "@/game-tft/Selects.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import ToggleLoadedDice from "@/game-tft/ToggleLoadedDice.jsx";
import ToggleTraitsAndItems from "@/game-tft/ToggleTraitsAndItems.jsx";
import { useLocalizedAugments } from "@/game-tft/use-augments.mjs";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useInGameBenchmarking from "@/game-tft/use-in-game-benchmarking.mjs";
import debounce from "@/util/debounce.mjs";
import deepEqual, { shallowEqual } from "@/util/deep-equal.mjs";
import { appLog, devError } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const IS_WEB_DEV = IS_DEV && !IS_APP;
const TOGGLE_COMPONENTS = {
  [TOGGLES_CHAMPION]: ToggleTraitsAndItems,
  [TOGGLES_LOADED_DICE]: ToggleLoadedDice,
};

function InGame() {
  const translationInstance = useTranslation();
  const { activeSubscriber } = useSnapshot(accountRefs);
  const { t } = translationInstance;
  const [set, setSet] = useState(getCurrentSet());
  const state = useSnapshot(readState);
  const routeInstance = useRoute();
  const { searchParams, currentPath } = routeInstance;
  const locale = getLocale();
  const isPBE = isFutureSet(set);
  const isRevival = isRevivalSet(set);
  const defaultFilters = state.tft.defaultFilters;
  const defaultMode = defaultFilters?.default_mode.mode;
  const defaultRank = defaultFilters?.modes_default_rank?.[defaultMode]?.rank;
  const compsFilters = getStatFilters(
    searchParams,
    "comps",
    isPBE,
    isRevival,
    defaultMode,
    defaultRank,
  );
  const statsFilters = getStatFilters(
    searchParams,
    "augments",
    isPBE,
    isRevival,
  );
  const [wndAttributes, setWndAttributes] = useState(undefined);
  const [settings, setSettings] = useState({
    toggles: { isSettings: false, isChampions: false },
    modal: undefined,
    tooltips: [],
    toggleType: "",
    searchInput: "",
    championSelected: "",
  });
  const gameData = state.volatile.gameData;
  const player = IS_WEB_DEV ? fixturesInGameSettings.player : gameData?.player;
  const opponents = IS_WEB_DEV
    ? fixturesInGameSettings.opponents
    : gameData?.opponents;
  const [overlayAppData, setOverlayAppData] = useState(undefined);
  handleMessage(EVENTS.TFT_OVERLAY_DATA, (data) => {
    appLog("TFT InGame TFT_OVERLAY_DATA loaded");
    setOverlayAppData(data);
    const summonerInfo = data.summonerInfo;
    const region = summonerInfo?.region;
    const gameName = summonerInfo?.gameName;
    const tagLine = summonerInfo?.tagLine || region?.toUpperCase();
    const derivedId =
      gameName &&
      tagLine &&
      getDerivedId(region, getDerivedRiotID(gameName, tagLine));
    if (!region || !gameName || !tagLine || !derivedId) return;
    getData(
      API.getMatchList(region, gameName, tagLine),
      GenericMatchListModel,
      ["tft", "matchLists_v2", derivedId],
      {
        shouldFetchIfPathExists: true,
        skipSafetyCheck: true,
        mergeFn: matchlistMergeFn,
      },
    );
  });
  const patchLong =
    overlayAppData?.matchInfo?.currentPatch?.detailed ??
    getCurrentPatchForStaticData();
  const patchShort =
    overlayAppData?.matchInfo?.currentPatch?.short ??
    getCurrentPatchForChampions();
  const toggleSettings = () => {
    setSettings((prev) => {
      const next = {
        ...prev,
        toggles: {
          isChampions: false,
          isSettings: !prev.toggles.isSettings,
        },
        searchInput: "",
        championSelected: "",
      };
      if (deepEqual(next, prev)) return prev;
      return next;
    });
  };
  const handleDisableSettings = () => {
    setSettings((prev) => {
      const next = {
        ...prev,
        toggles: {
          isChampions: false,
          isSettings: false,
        },
        tooltips: [],
        searchInput: "",
        modal: undefined,
      };
      if (!next.toggleType) next.toggleType = TOGGLES_CHAMPION;
      if (deepEqual(next, prev)) return prev;
      return next;
    });
  };
  const toggleBenchmarking = () => {
    setSettings((prev) => {
      const nextIsSetting = !prev.toggles.isSettings;
      const next = {
        ...prev,
        toggles: {
          isChampions: false,
          isSettings: nextIsSetting,
        },
        modal: nextIsSetting ? "benchmarking" : undefined,
      };
      if (deepEqual(next, prev)) return prev;
      return next;
    });
  };
  const toggleCompositions = () => {
    setSettings((prev) => {
      const nextIsSetting = !prev.toggles.isSettings;
      const next = {
        ...prev,
        toggles: {
          isChampions: false,
          isSettings: nextIsSetting,
        },
        modal: nextIsSetting ? "compositions" : undefined,
      };
      if (deepEqual(next, prev)) return prev;
      return next;
    });
  };
  const handleChampionsEnable = () => {
    setSettings((prev) => {
      const next = { ...prev };
      next.toggles = {
        isSettings: false,
        isChampions: true,
      };
      next.tooltips = [];
      next.modal = undefined;
      if (deepEqual(next, prev)) return prev;
      return next;
    });
  };
  const appVersion = state.volatile.appVersion?.value; // Assigned on top level route
  const gameId =
    state.settings.tft.lastGameId ?? overlayAppData?.matchInfo?.matchId;
  const wndAttributesApp = state.settings.wndAttributes;
  const tftOverlayData = state.volatile.tftOverlayData; // Assigned on exact route
  const remoteConfiguration = state.volatile?.tft?.overlayConfiguration;
  const summonerInfo =
    tftOverlayData?.summonerInfo ?? overlayAppData?.summonerInfo;
  const region = summonerInfo?.region;
  const gameName = summonerInfo?.gameName;
  const tagLine = summonerInfo?.tagLine;
  let tier = summonerInfo?.leagues?.find(
    (league) =>
      league?.queue_type ===
      QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTft],
  )?.tier;
  if (!tier || tier === "NONE") {
    tier = "GOLD";
  }

  const derivedId = IS_WEB_DEV
    ? getDerivedRiotID("Souless", "001")
    : gameName && tagLine && getDerivedRiotID(gameName, tagLine);
  const summoner = state.tft.accounts_v2?.[derivedId];
  // Core related features
  const queueId =
    state.settings?.lol?.lastQueueId ||
    overlayAppData?.matchInfo?.queueId ||
    "1100";
  const queue = QUEUE_TYPES[queueId];
  // revival set
  useEffect(() => {
    const revivalSet =
      queueId &&
      revivalSets.find(
        (s) => Number.parseInt(s.queueId) === Number.parseInt(queueId),
      );
    if (revivalSet) {
      setSet(revivalSet.set);
      const nextSearchParams = new URLSearchParams(searchParams);
      nextSearchParams.set("set", revivalSet.set?.replace("set", ""));
      nextSearchParams.sort();
      updateRoute(currentPath, nextSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, queueId]);
  const isGameData = IS_WEB_DEV ? true : !!(gameData && wndAttributes);
  // Static
  const augments = state.tft[set]?.augments;
  let augmentStats = state.tft.stats.augments[statsFilters]?.[set];
  // use current set augment stats if no stats for revival set
  if (!augmentStats?.length && isRevival) {
    augmentStats =
      state.tft.stats.augments[
        getStatFilters(searchParams, "augments", isPBE)
      ]?.[getCurrentSet()];
  }
  const localizedAugments = useLocalizedAugments(set);
  const items = state.tft[set]?.items;
  const legends = state.tft[set]?.legends?.[set];
  const unitStatsBySet = state.tft.stats.units?.[statsFilters]?.[set];
  const itemStatsBySet = state.tft.stats.items?.[statsFilters]?.[set];
  const championsLocalized = useLocalizedChampions(set);
  const champions = championsLocalized ?? state.tft[set]?.champions;
  const analyzedComps = state.tft.stats.comps[compsFilters]?.[set];
  const earlycomps = state.tft.stats.earlycomps?.[compsFilters]?.[set];
  const isEarlyComp =
    searchParams.get("early") === "true" && earlycomps?.length;
  const comps = useMemo(
    () => (isEarlyComp ? earlycomps : analyzedComps),
    [analyzedComps, earlycomps, isEarlyComp],
  );
  const [pinnedComp, setPinnedComp] = useState(undefined);
  const [offeredAugments, setOfferedAugments] = useState([]);
  const latestPatch = isPBE ? state.tft.pbePatch : state.tft.latestPatch;
  const patches = Object.keys(setPatches);
  const prevPatch = patches[patches.indexOf(latestPatch?.patch) - 1];
  let championPositioning = state.tft.championPositioning?.[latestPatch?.patch];
  championPositioning =
    championPositioning && Object.keys(championPositioning)?.length
      ? championPositioning
      : state.tft.championPositioning?.[prevPatch];
  const income = state.tft.stats.income?.[tier]?.[queueId];
  // const tierList = state.tft.comps.tierlist;
  const itemsLocalized = state.tft[set]?.localizedItems?.[locale];
  const traits = state.tft[set]?.traits;
  const benchmarkingStats = useInGameBenchmarking(gameData, { champions, set });
  let overlaysInGame = state.tft.overlaysInGame;
  const isSkinArtworkChampInfoOverlay =
    !!overlaysInGame.championInfoOverlay?.skinArtwork;
  const {
    isTFTItemsOverlayEnabled = false,
    isTFTCompsOverlayEnabled = false,
    isTFTNextOpponentEnabled = false,
    isChampionsOverlayEnabled = false,
    // isOpponentTagsEnabled = false,
    isLevelingOverlayEnabled = false,
    isTFTBenchmarkingOverlayEnabled = false,
    isStreamInfoOverlayEnabled = false,
    isTFTAugmentOverlayEnabled = false,
    streamInfoOverlay = {},
    itemCombinationsOverlay = {},
    compositionsOverlay = {},
    compositionsModalOverlay = {},
    benchmarkingOverlay = {},
  } = overlaysInGame;
  const updateSettings = (data) => {
    const newSettings = { ...overlaysInGame, ...data };
    overlaysInGame = newSettings;
    setOverlaySettings(newSettings, false);
  };
  handleMessage(EVENTS.TFT_OVERLAY_SETTINGS, (data) => {
    appLog("TFT InGame TFT_OVERLAY_SETTINGS loaded");
    updateSettings(data);
  });
  handleMessage(EVENTS.UPDATE_TFT_SETTINGS, (data) => {
    appLog("TFT InGame UPDATE_TFT_SETTINGS");
    updateSettings(data);
  });
  const search = searchParams.get("search");

  // tracking
  const captureData = (event) => {
    const newEvent = { ...event };
    const singleEvent = {
      username: derivedId,
      region: region,
      event: newEvent,
      tftSettings: overlaysInGame,
    };
    eventBus.emit(EVENT_TFT_OVERLAY_USAGE, singleEvent);
  };

  // Callbacks
  const removeAllTooltips = () => {
    setSettings((prev) => {
      if (!prev.tooltips.length) return prev;
      const next = { ...prev };
      next.tooltips = [];
      return next;
    });
  };
  const onChampionHover = useCallback(
    function onChampionHover(championKey) {
      return function handleChampionHover() {
        setSettings((prev) => {
          if (prev.championKey === championKey) return prev;
          const next = { ...prev };
          if (!next.toggleType) next.toggleType = TOGGLES_CHAMPION;
          next.championSelected = championKey;
          const ToolTipComponent = TOGGLE_COMPONENTS[next.toggleType];
          if (!ToolTipComponent) return prev;
          next.tooltips = [
            createElement(ToolTipComponent, {
              key: 0,
              index: 0,
              setState: setSettings,
              champion: next.championSelected,
              champions,
              unitStatsBySet,
              items,
              itemsLocalized,
              isSkinsEnabled: isSkinArtworkChampInfoOverlay,
              set,
              searchInput: next.searchInput,
              player,
              opponents,
              t,
            }),
          ];
          return next;
        });
      };
    },
    [
      champions,
      isSkinArtworkChampInfoOverlay,
      items,
      itemsLocalized,
      opponents,
      player,
      set,
      t,
      unitStatsBySet,
    ],
  );
  const handleChampionSearch = useCallback(
    function handleChampionSearch(value) {
      setSettings((prev) => {
        const next = { ...prev };
        next.searchInput = value;
        next.championSelected = "";
        if (!value || value === "") {
          next.tooltips = [];
          return next;
        }
        // Unit tooltip won't show without a toggle type
        // Eg. Champion vs Loaded Dice: We display different tooltips depending on toggle type
        if (!next.toggleType) next.toggleType = TOGGLES_CHAMPION;
        for (const key in champions) {
          const champion = champions[key];
          if (!champion?.name) continue;
          if (champion.name.toLowerCase().includes(value.toLowerCase())) {
            next.championSelected = champion.apiKey;
            const ToolTipComponent = TOGGLE_COMPONENTS[next.toggleType];
            if (!ToolTipComponent) return prev;
            next.tooltips = [
              createElement(ToolTipComponent, {
                key: 0,
                index: 0,
                setState: setSettings,
                champion: next.championSelected,
                champions,
                unitStatsBySet,
                items,
                itemsLocalized,
                isSkinsEnabled: isSkinArtworkChampInfoOverlay,
                set,
                searchInput: next.searchInput,
                player,
                opponents,
                t,
              }),
            ];
          }
        }
        return next;
      });
    },
    [
      champions,
      isSkinArtworkChampInfoOverlay,
      items,
      itemsLocalized,
      opponents,
      player,
      set,
      t,
      unitStatsBySet,
    ],
  );
  const onChampionSearch = debounce(handleChampionSearch, 1e2);
  function onToggleType(type) {
    return function handleToggleType() {
      captureData({
        name: "champion info",
        action: "click",
        target: type,
      });
      setSettings((prev) => {
        const next = { ...prev };
        next.toggleType = type;
        next.tooltips = [];
        return next;
      });
    };
  }
  // Effect: Mount
  useEffect(() => {
    if (IS_WEB_DEV) setOverlayFixtures();
  }, []);
  useEffect(() => {
    if (wndAttributesApp) {
      const next = {
        ...wndAttributesApp,
      };
      if (!next.scaleFactor) next.scaleFactor = INT_SCALE_FACTOR; // Set default if resolution table doesn't work
      setWndAttributes((prev) => {
        if (!prev) return next;
        if (shallowEqual(prev, next)) return prev;
        return next;
      });
    }
  }, [wndAttributes, wndAttributesApp]);

  useEffect(() => {
    const captureGameData = () => {
      // "firstCombatFrame" only happens at during each round
      if (!gameData?.firstCombatFrame || !champions || !items) return;
      // Clone and modify specific parts of gameData since we're modifying it in-place for tracking events
      // Data team requires the data to be a certain structure
      const opponents = [...gameData.opponents].map((opponent) => {
        const [tagLine, gameName] = opponent.name.split("#");
        return {
          ...opponent,
          gameName,
          tagLine,
        };
      });
      for (const opponent of opponents)
        modifyChampionStruct(opponent, champions, set, false);
      const player = {
        ...gameData.player,
        gameName: gameData.player.name.split("#")[0],
        tagLine: gameData.player.name.split("#")[1],
      };
      modifyChampionStruct(player, champions, set, true);
      const summonerName = gameData.player.name;
      const params = new URLSearchParams({
        matchId: gameId,
        summonerName,
        region,
        [`is${"Subscriber"}`]: activeSubscriber,
        version: appVersion,
      }).toString();
      const roundData = {
        game_id: gameId,
        summonerName,
        gameName: player.gameName,
        tagLine: player.tagLine,
        region,
        [`is${"Subscriber"}`]: activeSubscriber,
        queueId,
        leagues: summoner?.leagues || overlayAppData?.summonerInfo?.leagues,
        patch: patchShort,
        gameVersion: patchLong,
        roundData: {
          roundStage: gameData.roundStage,
          roundIndex: gameData.roundIndex,
          time: gameData.time,
          roundOpponent: gameData.roundOpponent,
          player: {
            ...player,
            rerolls: benchmarkingStats.rerolls,
            offeredAugments: offeredAugments,
          },
          opponents: [...opponents],
        },
      };
      blitzMessage(EVENTS.TFT_ROUND_DATA, roundData);
      eventBus.emit(EVENT_TFT_ROUND_DATA, roundData);
      eventBus.emit(EVENT_TFT_ROUND_STATE, { gameData, params });

      if (region && gameId) {
        uploadRoundData({
          gameId: `${region?.toUpperCase()}_${gameId}`,
          roundData: gameData,
          rerolls: benchmarkingStats?.rerolls,
          offeredAugments,
        });
      }
    };
    try {
      captureGameData();
    } catch (e) {
      devError("InGame Overlays Tracking Error", e);
    }
  }, [
    champions,
    gameData,
    items,
    appVersion,
    region,
    queueId,
    gameId,
    summoner?.leagues,
    activeSubscriber,
    set,
    patchShort,
    patchLong,
    benchmarkingStats.rerolls,
    overlayAppData?.summonerInfo?.leagues,
    summonerInfo?.tagLine,
    summonerInfo?.gameName,
    offeredAugments,
  ]);
  useEffect(() => {
    appLog("TFT InGame component mounted");
    blitzMessage(EVENTS.GET_TFT_OVERLAY_DATA);
    blitzMessage(EVENTS.GET_TFT_OVERLAY_SETTINGS);
  }, []);
  useEffect(() => {
    if (wndAttributes) {
      appLog("TFT InGame wndAttributes loaded");
    }
  }, [wndAttributes]);

  // fix scrollbar gutter
  useEffect(() => {
    const appScroller = globals.document.querySelector(".app-scroller");
    const memoScrollbarGutter = appScroller.style.scrollbarGutter;
    const memoOverflowY = appScroller.style.overflowY;
    appScroller.style.scrollbarGutter = "auto";
    appScroller.style.overflowY = "hidden";
    return () => {
      appScroller.style.scrollbarGutter = memoScrollbarGutter;
      appScroller.style.overflowY = memoOverflowY;
    };
  }, []);

  // Comp calcs
  const filters = SelectFilterDefaults(searchParams);
  const compsBlitzAnalyzed = useMemo(() => {
    if (!comps?.length) return [];
    const sortBy = searchParams.get("sort") ?? "tier";
    const sortDirection = sortBy === "avg_placement" ? "asc" : "desc";
    let data = [
      ...comps.map((comp) => {
        return {
          ...comp,
          units: comp.units?.map((unit) => {
            return {
              ...unit,
              key: unit.name,
              playCondition: !!comp.play_conditions?.find(
                (cond) => cond.name === unit.name,
              ),
            };
          }),
        };
      }),
    ];
    // traits filter
    if (filters.traits.length) {
      data = data.filter((comp) => {
        const compTraits = Object.keys(
          StaticTFT.getTraitsFromUnits(comp.units, set),
        );
        return filters.traits.every((trait) => compTraits.includes(trait));
      });
    }
    // champions filter
    if (filters.champions.length) {
      data = data.filter((comp) => {
        const units = comp.units.map((unit) =>
          StaticTFT.getChampKey(unit.name, set),
        );
        return filters.champions.every((champion) => units.includes(champion));
      });
    }
    // augment filter
    if (filters.augments?.length) {
      data = data.filter((comp) => {
        const clusterId = comp.cluster_id;
        return filters.augments.every((aug) =>
          augmentStats[aug]?.top_comps?.includes(clusterId),
        );
      });
    }
    // item filter
    if (filters.items?.length) {
      data = data.filter((comp) => {
        const compItems = comp.units.flatMap((unit) => unit.items);
        return filters.items.every((item) => compItems.includes(item));
      });
    }

    // search filter
    if (search?.length > 1) {
      data = data.filter((comp) => {
        const champions = comp.units.map(
          (unit) => StaticTFT.getChampInfo(unit.name, set)?.name,
        );
        const traits = Object.keys(
          StaticTFT.getTraitsFromUnits(comp.units, set),
        );
        return (
          champions.some((champ) =>
            champ?.toLowerCase().startsWith(search?.toLowerCase()),
          ) ||
          traits.some((trait) =>
            trait?.toLowerCase().startsWith(search?.toLowerCase()),
          )
        );
      });
    }

    if (sortBy === "tier") {
      return data
        .sort((a, b) => a.stats.avg_placement - b.stats.avg_placement)
        .sort((a, b) => a.stats.tier - b.stats.tier);
    }
    if (sortDirection === "desc")
      return data.sort((a, b) => b.stats[sortBy] - a.stats[sortBy]);
    if (sortDirection === "asc")
      return data.sort((a, b) => a.stats[sortBy] - b.stats[sortBy]);
    return data;
  }, [
    augmentStats,
    comps,
    filters.augments,
    filters.champions,
    filters.items,
    filters.traits,
    search,
    searchParams,
    set,
  ]);
  // Render
  if (
    !wndAttributes ||
    (remoteConfiguration ? !remoteConfiguration?.enabled : false)
  ) {
    return null;
  }

  return (
    <>
      {isTFTCompsOverlayEnabled &&
        (remoteConfiguration ? remoteConfiguration?.comps?.enabled : true) && (
          <InGameComps
            comps={compsBlitzAnalyzed}
            compsFilters={compsFilters}
            statsFilters={statsFilters}
            items={items}
            itemsLocalized={itemsLocalized}
            latestPatch={latestPatch}
            augments={augments}
            legends={legends}
            set={set}
            champions={champions}
            championsLocalized={championsLocalized}
            championPositioning={championPositioning}
            setTraits={traits}
            translationInstance={translationInstance}
            routeInstance={routeInstance}
            compositionsOverlay={compositionsOverlay}
            compositionsModalOverlay={compositionsModalOverlay}
            handleSettingsPanel={toggleCompositions}
            wndAttributes={wndAttributes}
            isPBE={isPBE}
            captureData={captureData}
            isEarlyComp={isEarlyComp}
            earlyComps={earlycomps}
            analyzedComps={analyzedComps}
            player={player}
            setPinnedComp={setPinnedComp}
          />
        )}
      {isTFTItemsOverlayEnabled &&
        (remoteConfiguration ? remoteConfiguration?.items?.enabled : true) && (
          <InGameOverlayItems
            items={items}
            itemsLocalized={itemsLocalized}
            itemStatsBySet={itemStatsBySet}
            set={set}
            itemCombinationsOverlay={itemCombinationsOverlay}
            wndAttributes={wndAttributes}
            captureData={captureData}
          />
        )}
      {isLevelingOverlayEnabled &&
        (remoteConfiguration ? remoteConfiguration?.leveling?.enabled : true) &&
        ((queue !== QUEUE_TYPES["1130"] && isGameData) || IS_WEB_DEV) &&
        createElement(
          InGameOverlayLeveling,
          IS_WEB_DEV
            ? { t, wndAttributes, captureData, ...fixturesInGameLeveling }
            : {
                t,
                wndAttributes,
                buyXpScreenPos: gameData.buyXpScreenPos,
                player: gameData.player,
                captureData: captureData,
              },
        )}
      {isTFTAugmentOverlayEnabled &&
        (remoteConfiguration ? remoteConfiguration?.augments?.enabled : true) &&
        isGameData && (
          <InGameOverlayAugments
            t={t}
            set={set}
            wndAttributes={wndAttributes}
            augmentStatic={augments}
            augmentStats={augmentStats}
            localizedAugments={localizedAugments}
            augments={
              IS_WEB_DEV
                ? [
                    {
                      name: "ClearMind2",
                      resource: "Clear Mind",
                      position: { x: 250, y: 500 },
                    },
                    {
                      name: "Jeweled-Lotus-II",
                      resource: "Jeweled Lotus II",
                      position: { x: 750, y: 500 },
                    },
                    {
                      name: "PortableForge2",
                      resource: "Portable Forge",
                      position: { x: 1250, y: 500 },
                    },
                  ]
                : gameData.augments
            }
            captureData={captureData}
            pinnedComp={pinnedComp}
            patch={latestPatch?.patch}
            isTFTCompsOverlayEnabled={isTFTCompsOverlayEnabled}
            roundStage={gameData?.roundStage}
            queue={queue}
            offeredAugments={offeredAugments}
            setOfferedAugments={setOfferedAugments}
          />
        )}
      <InGameOverlaySettings
        t={t}
        champions={champions}
        unitStatsBySet={unitStatsBySet}
        items={items}
        itemsLocalized={itemsLocalized}
        isChampionsOverlayEnabled={
          isChampionsOverlayEnabled &&
          (remoteConfiguration ? remoteConfiguration?.champions?.enabled : true)
        }
        settings={settings}
        toggleSettings={toggleSettings}
        handleChampionsEnable={handleChampionsEnable}
        handleDisableSettings={handleDisableSettings}
        removeAllTooltips={removeAllTooltips}
        onToggleType={onToggleType}
        onChampionHover={onChampionHover}
        onChampionSearch={onChampionSearch}
        overlaysInGame={overlaysInGame}
        queue={queue}
        set={set}
        isSkinsEnabled={isSkinArtworkChampInfoOverlay}
        wndAttributes={wndAttributes}
        player={player}
        opponents={opponents}
        remoteConfiguration={remoteConfiguration}
        captureData={captureData}
      />
      {/* {isOpponentTagsEnabled &&
        (isGameData || IS_WEB_DEV) &&
        createElement(
          InGameOverlayOpponentTags,
          IS_WEB_DEV
            ? {
                t,
                champions,
                items,
                traits,
                comps: compsProPicks,
                wndAttributes,
                ...fixturesInGameOpponentTags,
                isSkinsEnabled: isSkinArtworkChampInfoOverlay,
                set,
              }
            : {
                t,
                champions,
                items,
                traits,
                opponents: gameData.opponents,
                comps: compsProPicks,
                wndAttributes,
                isSkinsEnabled: isSkinArtworkChampInfoOverlay,
                set,
              }
        )} */}
      {isTFTNextOpponentEnabled &&
        (remoteConfiguration
          ? remoteConfiguration?.nextOpponent?.enabled
          : true) &&
        (isGameData || IS_WEB_DEV) &&
        createElement(
          InGameNextOpponentOverlay,
          IS_WEB_DEV
            ? { t, wndAttributes, captureData, ...fixturesInGameOpponentNext }
            : {
                t,
                wndAttributes,
                gameData,
                captureData,
              },
        )}
      {isTFTBenchmarkingOverlayEnabled &&
        (remoteConfiguration
          ? remoteConfiguration?.benchmarking?.enabled
          : true) &&
        (isGameData || IS_WEB_DEV) &&
        createElement(
          InGameOverlayBenchmarking,
          IS_WEB_DEV
            ? {
                t,
                champions,
                income,
                set,
                wndAttributes,
                benchmarkingSettings: benchmarkingOverlay,
                handleSettingsPanel: toggleBenchmarking,
                captureData,
                ...fixturesInGameBenchmarking,
              }
            : {
                t,
                champions,
                income,
                set,
                stats: benchmarkingStats,
                roundIndex: gameData.roundIndex,
                roundStage: gameData.roundStage,
                wndAttributes,
                benchmarkingSettings: benchmarkingOverlay,
                subscriber:
                  tftOverlayData?.subscriber || overlayAppData?.subscriber,
                summonerInfo: summonerInfo,
                matchInfo:
                  tftOverlayData?.matchInfo || overlayAppData?.matchInfo,
                handleSettingsPanel: toggleBenchmarking,
                captureData,
              },
        )}
      {isStreamInfoOverlayEnabled &&
        (remoteConfiguration ? remoteConfiguration?.streamer?.enabled : true) &&
        (tftOverlayData || overlayAppData) &&
        createElement(
          InGameOverlayStreamInfo,
          IS_WEB_DEV
            ? {
                t,
                streamInfoOverlay,
                wndAttributes,
                ...fixturesInGameStreamInfo,
                captureData,
              }
            : {
                t,
                streamInfoOverlay,
                wndAttributes,
                summonerInfo: summonerInfo,
                queue,
                captureData,
              },
        )}
    </>
  );
}

export default InGame;

export function meta() {
  return {
    title: ["common:navigation.overlays", "Overlays"],
    description: ["", ""],
  };
}
