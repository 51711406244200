import React from "react";
import { styled } from "goober";

import StaticTFT from "@/game-tft/static.mjs";

const ImageFrame = styled("div")`
  box-sizing: border-box;
  border: var(--sp-px) solid
    ${({ $borderColor }) => $borderColor || "var(--shade5)"};
  border-radius: var(--br);
  opacity: ${({ $opacity }) => $opacity || 1};
  ${({ $size }) =>
    $size
      ? `
          height: ${$size};
          width: ${$size};
        `
      : `
          height: var(--sp-10_5);
          width: var(--sp-10_5);
        `};
`;
const Image = styled("img")`
  box-sizing: border-box;
  display: block;
  background: var(--shade9);
  border-radius: var(--br);
  border: var(--sp-px) solid var(--shade8);
`;

const ItemImage = ({ itemKey, size, selectedSet, ...restProps }) => {
  return (
    <ImageFrame $size={size} {...restProps}>
      <Image
        src={StaticTFT.getItemImage(itemKey, selectedSet)}
        width={size}
        height={size}
      />
    </ImageFrame>
  );
};

export default ItemImage;
